.container {
    text-align: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  

  .message p {
    font-size: 2.3rem;
    color: #3191dc;
    font-weight: 800;
    animation: fadeIn 2s ease-in-out 1s

  }
  
  .heading {
    font-size: 3.3rem;
    color: #555;
    font-weight: 800;
    animation: fadeIn 2s ease-in-out 1s

  }
  
  .animation {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    background-color: #3191dc;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
  }
  
  .circle:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .circle:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }