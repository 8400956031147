.downloader {
  width: 20%;
  min-height: 128px;
  position: fixed;
  right: 18px;
  bottom: 18px;
  max-height: 700px;
  overflow-y: auto;
  border:1px dotted black
}
.downloader .card-header {
  color: #fff;
  background-color:#003466;
  display:flex;
  justify-content: center;
}
.downloader .card .list-group {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}
@media screen and (max-width: 500px) {
  .downloader {
    width: 40%;
  }
}