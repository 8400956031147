/* Default styles for desktop */
.wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

/* Ensure that text is below images on desktop */
.desktopItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* Mobile-specific styles */
@media screen and (max-width: 767px) {
    .mobileRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .mobileItem {
        flex: 1 1 45%;
        margin: 5px;
        text-align: center;
    }
}
/* Default styles for desktop */
.fontSize {
    font-size: 16px; /* Default font size for desktop */
}

/* Mobile-specific styles */
@media screen and (max-width: 767px) {
    .fontSize {
        font-size: 10px; /* Smaller font size for mobile */
    }

    .mobileItem h2 {
        font-size: 12px; /* Specific heading size adjustment for mobile */
    }

    .mobileItem div {
        font-size: 10px; /* General text size for mobile */
    }
}

