.audiocontainer {
  width: 60%;
  height: 100%;
  max-width: 1034px;
  max-height: 543px;
  margin: 0 auto;
}
.audioplayer-wrapper {
  position: relative;

  padding-bottom: 30px;
  height: 0;
}

.audioreact-player {
  box-sizing: border-box;
  padding-top: 7.7%;
  padding-bottom: 9.75%;
  padding-left: 8%;
  padding-right: 7.9%;
  position: absolute;
  border-radius: 10%;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
}

@media screen and (max-width: 1000px) {
  .mobileaudio-wrapper {
    position: relative;
    padding: 5%;
    margin: 2%;
   
    height: 113px;
  }
}

.mobileaudio-player {
    margin-top: 50px;
 }
@media screen and (max-width: 550px) {
  .mobileaudio-player {
     margin-top: 0px;
  }

  .mobileaudio-wrapper {
    height: 20px;
  }
}

