.classescontainer {
  width: 100%;
  height: 100%;
  max-width: 1034px;
  max-height: 543px;
  margin: 0 auto;
}
.classesplayer-wrapper {
  position: relative;
  padding-top: 2px;
  padding-bottom: 67.5%;
  height: 0;
}
.classesreact-player {
  box-sizing: border-box;
  padding-top: 7.7%;
  padding-bottom: 9.75%;
  padding-left: 8%;
  padding-right: 7.9%;
  position: absolute;
  border-radius: 10%;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
}

.noSelect {
  display: flex;
  justify-content: center;
  padding-top: 20%;
}
.textWrapper {
  padding: 10em;
  
  display: flex;
  justify-content: center;
  
  align-items: center;
}
@media screen and (max-width: 730px) {
  .noSelect {
    font-size: 9px;
  }
}

@media screen and (max-width: 1000px) {
  .mobileClassesContainer {
    margin: 1%;
  }
}
