.div1 {
    width: 100%;
    height: 100%;
    max-width: 1034px;
    max-height: 543px;
    margin: 0 auto;
}
.div1 .div2 {
    position: relative;
    padding-top: 2px;
    padding-bottom: 67.5%;
    height: 0;
}
.check:not(:fullscreen) {
    box-sizing: border-box;
    background: url('../../../../src/assets/img/images/mobileframe.webp') center center no-repeat;
    background-size: contain;
    padding-top:7.7%;
    padding-bottom: 9.75%;
    padding-left: 8%;
    padding-right: 7.9%;
    position: absolute;
    border-radius: 12%;
    top: 0;
    left: 0;
    width: 100%;
    height: 95%;
}

.mobileContainer{
    background: url('../../../../src/assets/img/images/mobileframe.webp') center center no-repeat !important;
    background-size: contain !important;
    padding:8.5%;
    padding-bottom: 10%;
    border-radius: 10%;
}


@media screen and (max-width: 1000px) {
    .mobileVideoContainer{
        margin:1%;
        
    }
}