.Rankingdiv1 {
    width: 100%;
    height: 100%;
    max-width: 1034px;
    max-height: 543px;
    margin: 0 auto;
}
.Rankingdiv1 .Rankingdiv2 {
    position: relative;
    padding-top: 2px;
    padding-bottom: 67.5%;
    height: 0;
}
.Rankingdiv2 iframe {
    box-sizing: border-box;
    padding:2%;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
    position: absolute;
    border-radius: 5%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.holder {
    background:url('../../assets/img/images/loading.gif') center center no-repeat
}