.entramientoContainer {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}
.entramientoContainer::-webkit-scrollbar {
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
.entramientoContainer {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.entrenamientoChild {
    scroll-snap-align: start;
    scroll-snap-stop: normal;
}

.buttondiv {
    visibility: hidden;
    position:absolute;
    z-index:100; 
    height: 82px; 
    width: 250px;
    top: 50%;
    left: 50%; 
    margin-left: -125px;
    margin-top: -41px
}

.videodiv:hover + .buttondiv {
    visibility: visible;
}

.buttondiv:hover {
    visibility: visible;
}

