.rbc-time-view .rbc-label {
    display: none;
}

.rbc-time-view .rbc-time-content {
    display: none;
}

.rbc-toolbar-label {
    text-transform: capitalize;
}

.rbc-month-view {
    text-transform: capitalize;
}

.rbc-button-link {
    text-transform: capitalize;
}

.rbc-overlay-header {
    text-transform: capitalize;
}

.rbc-event-content {
    overflow: scroll !important;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.rbc-event-content::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.rbc-today {
    background-color: #FFF44F;
}
   
.calendar-container {
    overflow-x: auto;
    display: flex;
    width: 96%;
    margin: 2%;
}

.calendar-container-child {
    flex-grow: 1;
}

.calendar-event-cell {
    display: flex;
}

.calendar-event-cell-image {
    max-width: 3em;
    border-radius: 3%;
}

.rbc-toolbar-label {
    font-weight: bolder;
    font-size: x-large;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .calendar-container {
        flex-direction: column; /* Stack vertically */
        padding: 10px; /* Add padding for mobile */
    }

    .calendar-container-child {
        height: auto; /* Allow height to adjust */
        width: 100%; /* Full width on mobile */
    }

    .rbc-toolbar-label {
        font-size: large; /* Smaller font size for mobile */
    }

    .calendar-event-cell {
        flex-direction: column; /* Stack images and text vertically */
        align-items: center; /* Center align items */
    }

    .calendar-event-cell-image {
        max-width: 2.5em; /* Smaller image size for mobile */
    }
}
