.popup-container {
    position: fixed; /* Change to fixed to center it relative to the viewport */
    top: 50%; /* Position the top 50% from the top of the viewport */
    left: 50%; /* Position the left 50% from the left of the viewport */
    transform: translate(-50%, -50%); /* Shift it left and up by half its size to center it */
    background-color: rgba(0, 0, 0, 0.8);
    background-image: url('../../assets/img/otp.png'); /* Keep your background image  ../../../assets/img/otp.png'*/
    background-size: cover; /* Ensure the image covers the container */
    background-position: center; /* Center the background image */
    color: #fff;
    padding: 30px;
    border-radius: 10px;
    z-index: 1001; /* Higher than overlay */
    width: 350px; /* Adjust width */
    height: 270px; /* Adjust height */
    text-align: center;
  
    /* Flexbox centering for content inside the popup */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .popup-close-btn {
    position: absolute; /* Position absolute for the close button */
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }