.stackStyle {
    display: flex;
}

@media screen and (max-width: 1000px) {
    .stackStyle{
        flex-direction: column-reverse;
        height: 96.5vh;
        justify-content: flex-end;
    }
}